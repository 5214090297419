body {
  color: #000;
  font-weight: bold;
  font-family: "GT Haptik", sans-serif !important;
  background-color: #f8fafc;
}

.amount {
  font-family: "Cartograph Mono CF", sans-serif;
}

.subtext {
  opacity: 0.5;
}

.subtitle {
  opacity: 0.5;
  font-size: 15px;
}

.threeboxProfileHover__style_boxShortAddress {
  color: black;
}

.ui.statistic>.value, .ui.statistics .statistic>.value {
  @media screen and (max-width: 475px) {
    font-size: 2.5rem !important;
  }
}

.title {
  font-size: 30px;
  line-height: 15px;
}

.button {
  padding: 20px 45px;

  &.ui.grey {
    // background: rgba(50, 50, 50, 0.9) !important;
    border: 2px solid #000;
    border-radius: 15px;
    color: #000;
    padding: 8px 15px;
    background-color: #fff;
    &:hover {
      color: #2567a4;
      background-color: #fff;
    }
  }
}

.formErrors {
  color: red;
  font-size: 12px;
}

.pill {
  border-radius: 20px !important;
  padding: 8px 20px !important;
  background: hsla(0, 0, 50%, 0.3) !important;
}

.uncolored {
  color: inherit !important;
}

.blurred {
  background: white !important;
}

.transparent {
  background: transparent !important;
}
.spacer {
  height: 70px;
}

.blurred.box {
  background: white !important;
  padding: 30px;
  border: 2px solid #d4e1ed;
  border-radius: 15px;
}

#background {
  .blurred_image {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -2;
    background: url("assets/moloch_owl.jpg") 0 / cover fixed;
    background-repeat: repeat !important;
  }

  .dark_overlay {
    position: fixed;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    z-index: -1;
    background: rgba(0, 0, 0, 0.5);
  }
}

#header {
  height: 80px;
  margin-top: 20px;
  margin-left: 50px;
  margin-right: 50px;
  .menu {
    font-size: 20px;
  }
  .right_dropdown>.text {
    background-color: white !important;
    border: 2px solid #000;
    
    color: #000;
    padding: 6px 15px;
    background-color: #fff;
    
    border-radius: 50px !important;
    &:hover {
      color: #2567a4;
    }
  }
  .logo {
    font-size: 20px;
    letter-spacing: 2px;
    text-decoration: none;
    a {
      color: inherit;
    }
    padding-left: 0;
    padding-right: 0;
  }
  .dropdown {
    .label {
      font-size: 15px;
      margin-right: 0;
    }
    .menu {
      margin-top: 10px;
      padding: 10px;
      font-size: 15px;
      font-weight: normal;
      input {
        background: rgba(255, 255, 255, 0.1);
        color: black;
        margin-bottom: 5px;
      }
      .button {
        padding: 10px 20px !important;
      }
      .item {
        padding: 20px 10px !important;
        color: inherit;
      }
      .item:hover:not(.submenu) {
        background-color: rgba(0,0,0,.1);
        cursor: pointer;
      }
      .link {
        color: inherit;
      }
      .dropdownItems i {
        margin-right: 12px;
      }
    }
  }
}

#wrapper {
  margin: 30px 50px;
}

#login {
  display: flex;
  justify-content: center;
  height: 70vh;
  align-items: center;
}

#homepage {
  .graph {
    width: 100%;
    height: 60vh;
  }
  .graph_values {
    padding-left: 20px;
    padding-right: 20px;
    .amount {
      font-size: 30px;
      line-height: 15px;
    }
  }
  .text_link {
    color: #000 !important;
  }

  
  @media screen and (min-width: 320px) and (max-width: 375px) { 
    .btn_link {
      margin-bottom:  10px !important;
      margin-left: 0px !important;
      width: 200px !important;
      padding-left: 5px !important;
      padding-right: 5px !important;
    }
    .graph_values {
      text-align: center !important;
      .amount {
        font-size: 14px !important;
        line-height: 20px !important;
      }
      .subtext {
        margin-bottom: 5px !important;
      }
    }
    .blurred {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }
  
  @media screen and (min-width: 376px) and (max-width: 768px) { 

    .btn_link {
      margin-bottom:  10px !important;
      margin-left: 0px !important;
      width: 200px !important;
      padding-left: 5px !important;
      padding-right: 5px !important;
    }
    .graph_values {
      text-align: center !important;
      .amount {
        font-size: 14px !important;
        line-height: 20px !important;
      }
      .subtext {
        margin-bottom: 5px !important;
      }
    }
    .blurred {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }
}

#member_list {
  .name {
    margin-top: 10px;
    margin-bottom: 0;
  }
  .divider {
    margin: 0;
  }
  .members_row {
    .member {
      margin-bottom: 40px;
    }
  }
  .member_avatar {
    margin-top: 25px;
  }
  
  @media screen and (min-width: 320px) and (max-width: 375px) { 
  
    .submit_button, .member_list_header {
      text-align: center !important;
    }
  }
  
  @media screen and (min-width: 376px) and (max-width: 768px) { 
  
    .submit_button, .member_list_header {
      text-align: center !important;
    }
  }

}

#member_detail {
  .details {
    margin-top: 20px;
  }
  .blurred.box {
    padding: 30px 60px;
  }
  .amount {
    font-size: 25px;
    line-height: 15px;
  }
  .avatar {
    margin: 50px;
  }
  .user {
    .segment {
      height: 100%;
    }
  }
  .user, .proposals{
    height: 550px !important;
  }
  .proposals {
    .segment {
      height: 100%;
      overflow-y: scroll;
    }
    .header {
      margin-bottom: 5px;
    }
    p {
      font-size: 1rem;
      color: inherit;
    }
    .dot {
      margin-right: 10px;
    }
    .divider {
      margin: 10px;
    }
  }

  @media screen and (min-width: 320px) and (max-width: 375px) { 

    .blurred.box { padding: 30px 7px !important; }
    .user { height: auto !important; }
  }
  
  @media screen and (min-width: 376px) and (max-width: 768px) { 
  
    .blurred.box { padding: 30px 20px !important; }
    .user { height: auto !important; }
    .proposals {margin-top: 20px !important}
  }
  
  @media screen and (min-width: 769px) and (max-width: 1024px) { 
  
    .blurred.box { padding: 30px 20px !important; }
    .user { height: auto !important; }
    .proposals {margin-top: 20px !important}
  }
  
  @media screen and (min-width: 1025px) and (max-width: 1366px) { 
  
    .blurred.box { padding: 30px 20px !important; }
  }
  
}

#proposal_list {
  .name {
    font-size: 15px;
    overflow-y: auto;
    -ms-overflow-style: none;  // IE 10+
    scrollbar-width: none;  // Firefox
  }
  .name::-webkit-scrollbar { 
    display: none;  // Safari and Chrome
  }
  .description {
    padding-top: 10px;
    padding-bottom: 20px;
    height: 25vh;
    overflow-y: auto;
  }
  .value_shares {
    padding-left: 20px;
    padding-right: 20px;
    .amount {
      font-size: 25px;
      line-height: 15px;
    }

    margin-bottom: 30px;
  }
  .deadlines {
    margin: 30px auto;
    .voting {}
    .grace {
      opacity: 0.5;
    }
  }
  .progress .bar {
    border-radius: 20px;
  }

  .ui.secondary.menu {
    overflow: scroll;
    -ms-overflow-style: none;  // IE 10+
    scrollbar-width: none;  // Firefox

    a {
      font-size: 20px;
    }

    .item {
      color: #000;
    }

    .active.item {
      border-color: #000;
    }
  }

  .ui.secondary.menu::-webkit-scrollbar { 
    display: none;  // Safari and Chrome
  }

  .ui.segment.active.tab {
    background: transparent;
    border-width: 0px;
  }

  .ui.small.progress.hide-bar {
    .bar {
      background-color: transparent
    }
  }
  
  @media only screen and (min-width: 320px) and (max-width: 374px) {
    .blurred.box { padding: 30px 7px; }
  }

  @media only screen and (min-width: 375px) and (max-width: 424px) {
    .blurred.box { padding: 30px 15px; }
  }

  @media only screen and (min-width: 320px) and (max-width: 425px) {
    .pill { padding: 8px 10px !important; }
  }
}

#proposal_detail {
  .title {
    margin-bottom: 80px;
  }
  .tokens {
    margin: 30px auto;
  }
  .amount {
    font-size: 25px;
    line-height: 15px;
  }
  .address {
    font-size: 20px;
    line-height: 15px;
  }
  .grace {
    opacity: 0.5;
  }
  .members_row {
    .image {
      width: 60px !important;
    }
    .button {
      width: 60px ;
      padding: 22px ;
      margin-top: 0.25em !important;
    }
  }
  .description {
    text-align: justify !important;
    min-height: 25vh;
  }

  .member_list {
    min-height: 25vh;
  }
  
  .member_avatar {
    margin-top: 25px;
  }

  .btn {
    min-width: 200px;
  }
  
  .pill {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .pill_column {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .segment {
    min-width: 100% !important;
  }

  .ui.small.progress.hide-bar {
    .bar {
      background-color: transparent
    }
  }
  
  @media screen and (min-width: 320px) and (max-width: 375px) { 
    .pill_column {
      text-align: center !important;
    }
    .pill {
      padding-left: 30px !important;
      padding-right: 30px !important;
    }
    .btn {
      width: 100px !important;
    }
  }
  
  @media screen and (min-width: 376px) and (max-width: 768px) { 
    .pill_column {
      text-align: center !important;
    }
    .pill {
      padding-left: 30px !important;
      padding-right: 30px !important;
    }
    .btn {
      width: 100px !important;
    }
    .tributes {
      margin-top: 10px !important;
    }
    .caret_btn {
      width: 60px !important;
      height: 60px !important;
      padding: 22px !important;
      }
  }
  
  @media screen and (min-width: 769px) and (max-width: 1825px) { 
    .btn {
      min-width: 100px !important;
    }
    .tributes {
      margin-top: 10px !important;
      padding: 5px !important;
    }

    .caret_btn {
      width: 50px !important;
      height: 50px !important;
      padding: 0 !important;
      }
  }
  
  @media screen and (min-width: 1325px )   { 
    .pill {
      padding-left: 30px !important;
      padding-right: 30px !important;
    }
    .caret_btn {
      width: 60px !important;
      height: 60px !important;
      padding: 22px !important;
    }
  }
}

#proposal_submission {
  input, textarea, select, .dropdown {
    background: rgba(255, 255, 255, 0.1);
    color: black;
  }
  label {
    color: black;
    opacity: 0.5;
  }
  i {
    float: left !important;
  }
  .amount {
    font-size: 25px;
    line-height: 15px;
  }
  .blurred.box {
    height: 100%;
  }
  .delete_icon {
    margin-top: 8px;
    text-align: center;
  }
  .assets_field {
    overflow-y: auto;
    min-height: 150px;
    max-height: 200px;
  }
  .submit_button {
    text-align: center !important;
  }
  
  .computer_delete_icon {
    display: block;
  }
  .mobile_delete_icon {
    display: none;
  }

  .icon_asset{
    padding: 0 !important;
  }
  
  .icon_asset input {
    padding-left: 2em !important;
  }
  
  @media screen and (min-width: 320px) and (max-width: 767px) { 

    .asset {
      min-width: 100% !important;
    }
    .membership .asset i {
      width: 30px;
    }
    .proposal_currency_dropdown {
      padding-left: 10px !important;
    }
    .assets_field {
      min-height: 100px !important;
    }
    .asset_amount {
      padding: 0 !important;
      min-width: 100% !important;
    }
    .blurred.box {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }

    .asset_field_grid {
      padding: 2px !important;
      text-align: right !important;
    }
    .asset_field_row {
      padding-left: 10px;
      padding-right: 10px;
    }
    .delete_icon, .add_icon {
      float: right !important;
    }
    .computer_delete_icon {
      display: none;
    }
    .mobile_delete_icon {
      display: block;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) { 
  
    .asset {
      min-width: 100% !important;
    }
    .asset_amount {
      padding: 0 !important;
      min-width: 100% !important;
    }
    .blurred.box {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }

    .asset_field_grid {
      padding: 2px !important;
      text-align: right !important;
    }
    .asset_field_row {
      padding-left: 10px;
      padding-right: 10px;
    }
    .delete_icon, .add_icon {
      float: right !important;
    }
    .computer_delete_icon {
      display: block;
    }
    .mobile_delete_icon {
      display: none;
    }
  }

  @media screen and (min-width: 1025px) { 
  
    .asset {
      min-width: 100% !important;
    }
    .asset_amount {
      padding: 0 !important;
      min-width: 100% !important;
    }
    .blurred.box {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }

    .asset_field_grid {
      padding: 2px !important;
      text-align: right !important;
    }
    .asset_field_row {
      padding-left: 10px;
      padding-right: 10px;
    }
    .delete_icon, .add_icon {
      float: right !important;
    }
    .computer_delete_icon {
      display: block;
    }
    .mobile_delete_icon {
      display: none;
    }
  }

  
  
}

.wordwrap { 
  white-space: pre-wrap;      /* CSS3 */   
  white-space: -moz-pre-wrap; /* Firefox */    
  white-space: -pre-wrap;     /* Opera <7 */   
  white-space: -o-pre-wrap;   /* Opera 7 */    
  word-wrap: break-word;      /* IE */
}